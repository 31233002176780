body, html {
    height: 100vh;
    width: 100vw;

    /* prevent background scrolling; issue on mobile devices */
    position: fixed; 
}

#root {
    height: 100%;
    background-color: #c7c7c7;
    color: black;
    width: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.app-row {
    width: 100vw !important;
    margin: 0px !important;
    padding: 0px !important;
}

.app-content {
    width: 100vw !important;
    height: 90vh !important;
    margin: 0px !important;
    padding: 0px;
    overflow-x: hidden !important;
    overflow-y: visible;
}

.page-background {
    background-color: #c7c7c7;
    width: 100%;
}

#drink-details {
    padding: 3%;
}

.drink-container {
    margin: 3%;
    padding: 3%;
    border-radius: 15px;
    background-color: #343A40;
}

.drink-jumbo {
    margin: 3%;
    padding: 5%;
    border-radius: 15px;
    padding: 20px 10px !important;
    background-color: #343A40 !important;
    color: white;
}

#drink-detail-img {
    display: flex !important;
    justify-content: center !important;
    max-height: 50vh !important;
}

#password-label {
    padding: 0;
}

#favorite-alert {
    margin-top: 15px !important;
    margin-bottom: 0px !important;
}

.page-intro-card {
    background-color: #c7c7c7;
    margin: 10px;
}

.iphone-buffer {
    padding-bottom: 75px !important;
}